import { ThemeProvider } from "@emotion/react";
import GlobalTheme from "../theme/GlobalTheme";
import { Card, CardMedia, Divider, Grid, responsiveFontSizes, Skeleton, Snackbar, SnackbarContent, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import PlaceIcon from '@mui/icons-material/Place';
import EventIcon from '@mui/icons-material/Event';
import PhoneIcon from '@mui/icons-material/Phone';
import CelebrationIcon from '@mui/icons-material/Celebration';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import PhotoAlbumIcon from '@mui/icons-material/PhotoAlbum';

import axios from "axios";
import { Link, useParams } from "react-router-dom";
import Footer from "../components/Footer";

import TimelineOppositeContent, {
    timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';

import { motion } from "framer-motion";
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineDot, TimelineContent } from "@mui/lab";
import ParallaxParticles from "../components/ParallaxParticles";

export interface Deliverable {
    name: string;
    status: string;
    link: string;
    pin: string;
}

function convertGoogleDriveUrl(shareableUrl: string) {
    const fileIdMatch = shareableUrl.match(/[-\w]{25,}/);
    if (!fileIdMatch) {
        return shareableUrl;
    }
    const fileId = fileIdMatch[0];
    return `https://lh3.google.com/u/0/d/${fileId}`;
}

export default function Delivery() {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [date, setDate] = useState("");
    const [location, setLocation] = useState("");
    const [coverPicture, setCoverPicture] = useState("");
    const [deliverables, setDeliverables] = useState<Deliverable[]>([])
    const [notes, setNotes] = useState("");

    const [showAlert, setShowAlert] = useState(true);
    const [alertMessage, setAlertMessage] = useState("Loading...");

    const targetRef = useRef<HTMLDivElement | null>(null);
    const bigDevice = useMediaQuery(GlobalTheme.breakpoints.up('md'));

    useEffect(() => {
        if (!bigDevice) {
            targetRef.current?.scrollIntoView({ behavior: 'smooth' })
        }
    }, [loading])

    useEffect(() => {
        axios.post("/signature-moments-data-ingest", {
            action: 'GET_DELIVERY_DETAILS',
            delivery_no: id,
            email: "1",
            password: "1"
        }).then(response => {
            if (response.status === 200) {
                setName(response.data[1]);
                setPhone(response.data[2]);
                setEmail(response.data[3]);
                setDate(response.data[4]);
                setLocation(response.data[5]);
                setCoverPicture(response.data[6]);
                setNotes(response.data[7]);
                let list: Deliverable[] = [];
                for (let i = 8; i < 28; i = i+4) {
                    let deliverable: Deliverable = {
                        name: response.data[i],
                        status: response.data[i+1],
                        link: response.data[i+2],
                        pin: response.data[i+3],
                    }
                    list.push(deliverable);
                }
                setDeliverables(list);
                setLoading(false);
                setShowAlert(false);
                setAlertMessage("");
            } else {
                setAlertMessage("Failed! Please try again.");
                setShowAlert(true);
            }
        }).catch(error => {
            setAlertMessage("Failed! Please try again.");
            setShowAlert(true);
        })
    }, []);

    return (
        <ThemeProvider theme={responsiveFontSizes(GlobalTheme)}>
            <Grid
                container
                component="main"
                textAlign={"center"}
                sx={{
                    backgroundColor: "primary.main",
                    minHeight: "100vh"
                }}
            >
                <Grid item xs={12} md={6} p={0} alignContent={"center"}>
                    <ParallaxParticles/>
                </Grid>
                {
                    loading ? bigDevice ? <Grid item xs={12} md={6} p={2} alignContent={"center"} sx={{ backgroundColor: "primary.light" }}>
                        <Grid container justifyContent={"center"} px={3}>
                            <Grid item xs={6}>
                                <Skeleton height={200} />
                            </Grid>
                            <Grid item xs={10}>
                                <Skeleton height={150} />
                            </Grid>
                            <Grid item xs={8}>
                                <Skeleton height={100} />
                            </Grid>
                            <Grid item xs={12} py={1}>
                                <Skeleton height={50} />
                            </Grid>
                            <Grid item xs={12} py={1}>
                                <Skeleton height={50} />
                            </Grid>
                            <Grid item xs={12} py={1}>
                                <Skeleton height={50} />
                            </Grid>
                            <Grid item xs={6} py={1}>
                                <Skeleton height={50} />
                            </Grid>
                        </Grid>
                    </Grid> : null : <Grid ref={targetRef} item xs={12} md={6} p={2} alignContent={"center"} sx={{ backgroundColor: "primary.light" }}>
                        <motion.div
                            initial={{ opacity: 0, y: 100 }}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                                transition: {
                                    duration: 1
                                }
                            }}
                            viewport={{ once: true }}
                        >
                            <Grid container>
                                <Grid item xs={12} pt={2}>
                                    <Typography variant="h3" fontWeight={"700"} p={1}>{name}</Typography>
                                </Grid>
                                <Grid item xs={12} py={2} sx={{ textAlign: '-webkit-center' }}>
                                    <motion.div
                                        initial={{ x: -300, opacity: 0 }}
                                        whileHover={{ scale: 1.1 }}
                                        whileInView={{
                                            x: 0,
                                            opacity: 1,
                                            transition: {
                                                type: "spring",
                                                bounce: 0.4,
                                                duration: 2
                                            }
                                        }}
                                        whileTap={{ scale: 0.9 }}
                                        viewport={{ once: true }}
                                    >
                                        <Card elevation={16} sx={{ width: '95%', maxWidth: 500, background: "black", borderRadius: 3 }}>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <CardMedia
                                                        component="img"
                                                        sx={{ height: '100%', maxHeight: 250 }}
                                                        image={convertGoogleDriveUrl(coverPicture)}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} p={1} alignContent="center">
                                                    {
                                                        date !== "" ?
                                                            <Grid item xs={12} py={1}>
                                                                <EventIcon color={"secondary"} />
                                                                <Typography variant="body1" color={"secondary"}>{date}</Typography>
                                                            </Grid> : null
                                                    }
                                                    {
                                                        location !== "" ?
                                                            <Grid item xs={12} py={1}>
                                                                <PlaceIcon color={"secondary"} />
                                                                <Typography color={"secondary"}>{location}</Typography>
                                                            </Grid> : null
                                                    }
                                                    {
                                                        phone !== "" ?
                                                            <Grid item xs={12} py={1}>
                                                                <PhoneIcon color={"secondary"} />
                                                                <Typography color={"secondary"}>{phone}</Typography>
                                                            </Grid> : null
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </motion.div>
                                </Grid>
                                <Grid item xs={12} pb={1} py={1} px={{ xs: 2, md: 4, lg: 8, xl: 12 }} >
                                    <Typography>{notes}</Typography>
                                </Grid>
                                <Grid item xs={12} py={1} px={{ xs: 2, md: 4, lg: 8, xl: 12 }}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12} mb={-1}>
                                    <Typography variant="h4" fontWeight={"700"} p={1}>Timeline</Typography>
                                </Grid>
                                <Grid item xs={12} maxHeight={bigDevice ? '35vh' : '100vh'} overflow="scroll">
                                    <Timeline sx={{
                                        [`& .${timelineOppositeContentClasses.root}`]: {
                                            flex: bigDevice ? 1.0 : 0.5,
                                        },
                                    }}>
                                        <motion.div
                                            initial={{ opacity: 0, scale: 0.1 }}
                                            whileHover={{ scale: 1.1 }}
                                            whileInView={{
                                                opacity: 1,
                                                scale: 1.0,
                                                transition: {
                                                    duration: 1
                                                }
                                            }}
                                            whileTap={{ scale: 0.9 }}
                                            viewport={{ once: true }}
                                        >
                                            <TimelineItem>
                                                <TimelineOppositeContent
                                                    sx={{ m: 'auto 0' }}
                                                    align="right"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    {date}
                                                </TimelineOppositeContent>
                                                <TimelineSeparator>
                                                    <TimelineConnector />
                                                    <TimelineDot color="primary">
                                                        <CelebrationIcon />
                                                    </TimelineDot>
                                                    <TimelineConnector />
                                                </TimelineSeparator>
                                                <TimelineContent sx={{ m: "auto 0" }}>
                                                    <Typography variant="h6" component="span">
                                                        Shoot Day
                                                    </Typography>
                                                    <Typography variant="body2">Real People, Real Stories, Real Moments</Typography>
                                                </TimelineContent>
                                            </TimelineItem>
                                        </motion.div>
                                        {deliverables.map((deliverable, index) => {
                                            return deliverable.name !== "" ? <motion.div
                                                key={index}
                                                initial={{ opacity: 0, scale: 0.1 }}
                                                whileHover={{ scale: 1.1 }}
                                                whileInView={{
                                                    opacity: 1,
                                                    scale: 1.0,
                                                    transition: {
                                                        duration: 1
                                                    }
                                                }}
                                                whileTap={{ scale: 0.9 }}
                                                viewport={{ once: true }}
                                            >
                                                <TimelineItem>
                                                    <TimelineOppositeContent
                                                        sx={{ m: 'auto 0' }}
                                                        align="right"
                                                        variant="body2"
                                                        color={deliverable.link === "" ? "grey" : "primary"}
                                                    >
                                                        {deliverable.status}
                                                    </TimelineOppositeContent>
                                                    <TimelineSeparator>
                                                        <TimelineConnector />
                                                        <TimelineDot color={deliverable.link === "" ? "grey" : "primary"}>
                                                            {
                                                                deliverable.name?.toLowerCase().includes("photo") ? <PhotoCameraIcon />
                                                                    : deliverable.name?.toLowerCase().includes("video") ? <VideoCameraFrontIcon />
                                                                        : deliverable.name?.toLowerCase().includes("podcast") ? <PodcastsIcon />
                                                                            : deliverable.name?.toLowerCase().includes("reel")
                                                                            || deliverable.name?.toLowerCase().includes("documentry")   ? <VideoLibraryIcon />
                                                                                : deliverable.name?.toLowerCase().includes("album")  ? <PhotoAlbumIcon /> : null
                                                            }
                                                        </TimelineDot>
                                                        <TimelineConnector />
                                                    </TimelineSeparator>
                                                    <TimelineContent sx={{ m: "auto 0" }}>
                                                        <Typography variant="h6" component="span">
                                                            {deliverable.name}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            {deliverable.link !== "" ?
                                                                <Link to={deliverable.link} target="_blank" rel="noopener noreferrer">Link</Link>
                                                                : null}
                                                            {deliverable.pin !== "" ? ", Password: " + deliverable.pin : ""}
                                                        </Typography>
                                                    </TimelineContent>
                                                </TimelineItem>
                                            </motion.div> : null;
                                        })}
                                    </Timeline>
                                </Grid>
                                <Grid item xs={12} px={{ xs: 2 }} pb={2} sx={{textAlign: "-webkit-center"}}>
                                    <Typography maxWidth={800} variant="subtitle1">
                                        Please leave us a Google review! <Link to="https://g.page/r/CShqP4EZ0s7CEAI/review" target="_blank" rel="noopener noreferrer" style={{color: "black"}}>Link</Link>
                                        <br/>
                                        Thanks a bunch!
                                    </Typography>
                                </Grid>
                            </Grid>
                        </motion.div>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Typography variant="h4" fontWeight={"700"} px={1} py={{ xs: 4, md: 6, lg: 8 }}  color="primary.light">We Value Your Feedback!</Typography>
                </Grid>
                <Grid item xs={12} px={{ xs: 3, md: 4, lg: 6 }} color="primary.light" sx={{textAlign: "-webkit-center"}}>
                    <Typography maxWidth={600} variant="subtitle1">
                        We hope you had a great experience with us. Your feedback helps us improve and helps others make informed decisions.
                        <br/>
                        If you enjoyed our service, we would be incredibly grateful if you could leave us a review on Google. It only takes a few moments and would mean a lot to us!
                    </Typography>
                </Grid>

            </Grid>
            {
                !loading ? <Footer /> : null
            }
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000}
                open={showAlert} onClose={() => setShowAlert(false)}>
                <SnackbarContent message={alertMessage} sx={{
                    borderRadius: 3,
                    color: 'primary.main',
                    justifyContent: 'center',
                    backgroundColor: 'primary.light',
                }} />
            </Snackbar>
        </ThemeProvider>
    )
}