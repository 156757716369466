import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import {Deliverable} from "./Delivery";
import GlobalTheme from "../theme/GlobalTheme";
import {
    Box,
    Card,
    Grid,
    Paper, Skeleton, Snackbar, SnackbarContent, Table, TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    ThemeProvider,
    Typography
} from "@mui/material";
import Footer from "../components/Footer";

const currencyFormatter = (value : number) => {
    const formatter = new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        maximumFractionDigits: 0,
    });
    return formatter.format(value).replace(/^(\D+)/, '$1 ').replace(/\s+/, ' ');
}

const updateViewport = (width: number) => {
    const viewportMeta = document.querySelector('meta[name="viewport"]');
    if (viewportMeta) {
        viewportMeta.setAttribute('content', `width=${width}`);
    } else {
        // If the viewport meta tag doesn't exist, create it
        const meta = document.createElement('meta');
        meta.name = "viewport";
        meta.content = `width=${width}`;
        document.head.appendChild(meta);
    }
}

export default function Invoice() {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);

    const[date, setDate] = useState('');
    const[name, setName] = useState('');
    const[address, setAddress] = useState('');
    const[phone, setPhone] = useState('');
    const[gstIn, setGstIn] = useState('');
    const[items, setItems] = useState('');
    const[description, setDescription] = useState('');
    const[notes, setNotes] = useState('');
    const[units, setUnits] = useState(0);
    const[price, setPrice] = useState(0);
    const[total, setTotal] = useState(0);
    const[cGst, setCGst] = useState(0);
    const[sGst, setSGst] = useState(0);

    const [showAlert, setShowAlert] = useState(true);
    const [alertMessage, setAlertMessage] = useState("Loading...");

    useEffect(() => {
        updateViewport(912);
        axios.post("/signature-moments-data-ingest", {
            action: 'GET_INVOICE_DETAILS',
            invoice_no: id
        }).then(response => {
            if (response.status === 200) {
                setDate(response.data[1]);
                setName(response.data[2]);
                setAddress(response.data[3]);
                setPhone(response.data[4]);
                setGstIn(response.data[5]);
                setItems(response.data[6]);
                setDescription(response.data[7]);
                setNotes(response.data[8]);
                setUnits(Number.parseFloat(response.data[9].replaceAll(",","")));
                setPrice(Number.parseFloat(response.data[10].replaceAll(",","")));
                setTotal(Number.parseFloat(response.data[11].replaceAll(",","")));
                setCGst(Number.parseFloat(response.data[12].replaceAll("%","")));
                setSGst(Number.parseFloat(response.data[13].replaceAll("%","")));
                setAlertMessage("");
                setShowAlert(false);
            } else {
                setAlertMessage("Failed! Please try again.");
                setShowAlert(true);
            }
        }).catch(error => {
            setAlertMessage("Failed! Please try again.");
            setShowAlert(true);
        }).finally(() => setLoading(false));
    }, []);

    return <ThemeProvider theme={GlobalTheme}>
        <Grid container bgcolor='black' alignContent='start' height='100vh' overflow='scroll'>
            <Grid item xs={12} textAlign='center' pt={3}>
                <img src={"/logo.png"} width={"10%"} style={{ minWidth: "180px" }} alt="logo" />
            </Grid>
            <Grid item xs={12} textAlign='center' pb={1} px={2}>
                <Typography variant='body1' fontWeight={100} fontSize='0.8rem' color={'lightgrey'}>
                    Signature Moments<br/>16A, New 26B, Kongu Nagar, Ramanthapuram, Coimbatore - 641045.<br/>GSTIN: 33AEJFS4793R1Z1
                </Typography>
            </Grid>
            <Grid item xs={12} textAlign='center' >
                <Typography variant='body1' fontWeight={700} fontSize='2.5rem' color={'white'}>INVOICE</Typography>
            </Grid>
            <Grid item xs={12} p={2} justifyItems='center'>
                <Paper sx={{p: {xs: 2, sm: 3, md: 4}, borderRadius: 3, maxWidth: '1000px', width: '-webkit-fill-available'}}>
                    <Grid container>
                        <Grid item xs={12} md={6} pb={3}>
                            <Grid container>
                                <Grid item xs={12} pb={1}>
                                    <Typography variant='h4' fontWeight={700}>{loading ? <Skeleton width={300}/> : name}</Typography>
                                </Grid>
                                <Grid item xs={12} pb={0.25} color='grey'>
                                    <Typography>{loading ? <Skeleton width={250} height={80}/> : address}</Typography>
                                </Grid>
                                <Grid item xs={12} pb={0.25}>
                                    <Typography>{loading ? <Skeleton width={200}/> : phone}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>{loading ? <Skeleton width={200}/> : gstIn}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} pb={3}>
                            <Grid container sx={{textAlign: '-webkit-right'}}>
                                <Grid item xs={12}>
                                    <Typography variant='h4' fontWeight={700}>{loading ? <Skeleton width={150}/> : id}</Typography>
                                </Grid>
                                <Grid item xs={12} pb={0.25} color='grey'>
                                    <Typography fontSize={14}>{loading ? <Skeleton width={100}/> : date}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <TableContainer>
                                <Table>
                                    <TableHead sx={{backgroundColor: 'lightgrey'}}>
                                        <TableRow>
                                            <TableCell align='center'>
                                                NO
                                            </TableCell>
                                            <TableCell align='center'>
                                                ITEM
                                            </TableCell>
                                            <TableCell align='center'>
                                                DESCRIPTION
                                            </TableCell>
                                            <TableCell align='center'>
                                                NOTES
                                            </TableCell>
                                            <TableCell align='right'>
                                                UNITS
                                            </TableCell>
                                            <TableCell align='right'>
                                                PRICE
                                            </TableCell>
                                            <TableCell align='right'>
                                                TOTAL
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align='center'>
                                                {loading ? <Skeleton /> : 1}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {loading ? <Skeleton /> : items}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {loading ? <Skeleton /> : description}
                                            </TableCell>
                                            <TableCell align='center'>
                                                {loading ? <Skeleton /> : notes}
                                            </TableCell>
                                            <TableCell align='right'>
                                                {loading ? <Skeleton /> : units}
                                            </TableCell>
                                            <TableCell align='right'>
                                                {loading ? <Skeleton /> : currencyFormatter(price)}
                                            </TableCell>
                                            <TableCell align='right'>
                                                {loading ? <Skeleton /> : currencyFormatter(total)}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{borderBottom: 'none'}}>
                                            </TableCell>
                                            <TableCell sx={{borderBottom: 'none'}}>
                                            </TableCell>
                                            <TableCell sx={{borderBottom: 'none'}}>
                                            </TableCell>
                                            <TableCell sx={{borderBottom: 'none'}}>
                                            </TableCell>
                                            <TableCell sx={{borderBottom: 'none'}}>
                                            </TableCell>
                                            <TableCell align='right' size='small'>
                                                <Typography fontSize='0.8rem' color='grey' fontWeight={700}>
                                                    SUB TOTAL
                                                </Typography>
                                            </TableCell>
                                            <TableCell align='right' size='small'>
                                                <Typography fontSize='1rem'>
                                                    {loading ? <Skeleton /> : currencyFormatter(price)}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{borderBottom: 'none'}}>
                                            </TableCell>
                                            <TableCell sx={{borderBottom: 'none'}}>
                                            </TableCell>
                                            <TableCell sx={{borderBottom: 'none'}}>
                                            </TableCell>
                                            <TableCell sx={{borderBottom: 'none'}}>
                                            </TableCell>
                                            <TableCell sx={{borderBottom: 'none'}}>
                                            </TableCell>
                                            <TableCell align='right' size='small'>
                                                <Typography fontSize='0.8rem' color='grey' fontWeight={700}>
                                                    {'CGST (' + cGst + '%)'}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align='right' size='small'>
                                                <Typography fontSize='1rem'>
                                                    {loading ? <Skeleton /> : currencyFormatter(price * cGst / 100)}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{borderBottom: 'none'}}>
                                            </TableCell>
                                            <TableCell sx={{borderBottom: 'none'}}>
                                            </TableCell>
                                            <TableCell sx={{borderBottom: 'none'}}>
                                            </TableCell>
                                            <TableCell sx={{borderBottom: 'none'}}>
                                            </TableCell>
                                            <TableCell sx={{borderBottom: 'none'}}>
                                            </TableCell>
                                            <TableCell align='right' size='small'>
                                                <Typography fontSize='0.8rem' color='grey' fontWeight={700}>
                                                    {'SGST (' + sGst + '%)'}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align='right' size='small'>
                                                <Typography fontSize='1rem'>
                                                    {loading ? <Skeleton /> : currencyFormatter(price * sGst / 100)}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{borderBottom: 'none'}}>
                                            </TableCell>
                                            <TableCell sx={{borderBottom: 'none'}}>
                                            </TableCell>
                                            <TableCell sx={{borderBottom: 'none'}}>
                                            </TableCell>
                                            <TableCell sx={{borderBottom: 'none'}}>
                                            </TableCell>
                                            <TableCell sx={{borderBottom: 'none'}}>
                                            </TableCell>
                                            <TableCell align='right' size='small'>
                                                <Typography fontSize='1rem' fontWeight={700}>
                                                    TOTAL
                                                </Typography>
                                            </TableCell>
                                            <TableCell align='right' size='small'>
                                                <Typography fontSize='1rem'>
                                                    {loading ? <Skeleton /> : currencyFormatter(price + (price * cGst / 100) + (price * sGst / 100))}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid item xs={12} pt={2}>
                            <Grid container xs={12} md={6} lg={4}>
                                <Grid item xs={12} pb={1}>
                                    <Typography variant='h5' fontWeight={700}>
                                        Account Details
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography fontWeight={700} color='grey'>
                                        Name
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography>
                                        {loading ? <Skeleton width={175}/> : "SM Media LLP"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography fontWeight={700} color='grey'>
                                        Bank
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography>
                                        {loading ? <Skeleton width={175}/> : "Karnataka Bank"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} fontWeight={700}>
                                    <Typography fontWeight={700} color='grey'>
                                        Branch
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography>
                                        {loading ? <Skeleton width={175}/> : "Trichy Road, Coimbatore."}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography fontWeight={700} color='grey'>
                                        Account
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography>
                                        {loading ? <Skeleton width={175}/> : "1537000600102901"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography fontWeight={700} color='grey'>
                                        IFSC
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography>
                                        {loading ? <Skeleton width={175}/> : "KARB0000153"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography fontWeight={700} color='grey'>
                                        GSTIN
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography>
                                        {loading ? <Skeleton width={175}/> : "33AEJFS4793R1Z1"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Footer />
            </Grid>
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000}
                      open={showAlert} onClose={() => setShowAlert(false)}>
                <SnackbarContent message={alertMessage} sx={{
                    borderRadius: 3,
                    color: 'primary.main',
                    justifyContent: 'center',
                    backgroundColor: 'primary.light',
                }} />
            </Snackbar>
        </Grid>
    </ThemeProvider>
}